var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-12 col-md-12 col-sm-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "설비 목록",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  isExcelDown: false,
                  editable: _vm.editable,
                  noDataLabel: _vm.$language(
                    "점검계획할 설비를 추가하세요. 저장할 시 점검결과를 등록 할 수 있습니다."
                  ),
                  selection: "multiple",
                  checkClickFlag: false,
                  cardClass: "topcolor-orange",
                  rowKey: "minEquipmentCheckId",
                },
                on: { linkClick: _vm.linkClick },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c("font", { attrs: { color: "#C10015" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$language(
                                  "※ 법정설비가 아닌 경우 점검유형은 자체(일상)로 고정됩니다."
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "sopMocName"
                          ? [
                              _c("q-btn", {
                                staticClass:
                                  "tableinnerBtn tableinnerUserSearch",
                                class: {
                                  "tableinnerUserSearch-left": true,
                                  "tableinnerUserSearch-right": true,
                                },
                                attrs: {
                                  flat: "",
                                  color: "blue-6",
                                  disable: props.row.disable,
                                  label: props.row.sopMocName,
                                  icon: "search",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.linkClick(props.row)
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                        col.name === "statusCd"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  class:
                                    props.row.statusCd === "MCSC000020"
                                      ? "blinking"
                                      : null,
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: _vm.setTagColor(props.row.statusCd),
                                  },
                                },
                                [
                                  props.row.statusCd === "MCSC000020"
                                    ? _c("q-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { name: "alarm" },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.setTagName(props.row.statusCd)
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        col.name === "equipmentCheckKindCd"
                          ? [
                              props.row.lawEquipmentFlag === "Y"
                                ? _c("c-select", {
                                    attrs: {
                                      stype: "tableselect",
                                      disabled: props.row.disable,
                                      codeGroupCd: "MDM_CHECK_KIND_CD",
                                      editable: _vm.editable,
                                      type: "edit",
                                      itemValue: "code",
                                      itemText: "codeName",
                                    },
                                    on: {
                                      datachange: function ($event) {
                                        return _vm.datachange(props)
                                      },
                                    },
                                    model: {
                                      value: props.row["equipmentCheckKindCd"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          props.row,
                                          "equipmentCheckKindCd",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row['equipmentCheckKindCd']",
                                    },
                                  })
                                : _c("c-select", {
                                    attrs: {
                                      stype: "tableselect",
                                      disabled: true,
                                      editable: _vm.editable,
                                      type: "edit",
                                      itemValue: "code",
                                      itemText: "codeName",
                                      comboItems: [
                                        {
                                          code: "MCKC000005",
                                          codeName: _vm.$language("자체(일상)"),
                                        },
                                      ],
                                    },
                                    on: {
                                      datachange: function ($event) {
                                        return _vm.datachange(props)
                                      },
                                    },
                                    model: {
                                      value: props.row["equipmentCheckKindCd"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          props.row,
                                          "equipmentCheckKindCd",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row['equipmentCheckKindCd']",
                                    },
                                  }),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.popupParam.equipmentCd && _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "추가",
                                icon: "add",
                              },
                              on: { btnClicked: _vm.addRow },
                            })
                          : _vm._e(),
                        !_vm.popupParam.equipmentCd && _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "설비선택",
                                icon: "add",
                              },
                              on: { btnClicked: _vm.add },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.saveList.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "삭제",
                                icon: "remove",
                              },
                              on: { btnClicked: _vm.remove },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.saveList.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.insertUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.saveList,
                                mappingType: "POST",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveInspection,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }